@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
  /* margin:0;
  padding: 0; */
  /* box-sizing : border-box;  */
}

body {
  margin: 0;
  background: #f6f9ff;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}