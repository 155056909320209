.logo {
  height: 3rem;
}

.validpasswword {
  /* width: 325px; */
  height: min-content;
  color: red;
  display: flex;
  list-style: none;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 5px;
}
.datePicker {
  margin: 20px;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(24, 125, 226, 0.08) !important;
}
.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
}
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  /* background-color:lightgray; */
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.toolBar {
  display: none;
}
